import { LogoutRoute } from '@app/global/Route/routes'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { refresh } from '../api/auth.api'

import { globalRequestContainer } from '@app/global/Api/request'

const SIGN_IN_ENDPOINT = '/api/auth/sign-in'
const REFRESH_ENDPOINT = '/api/auth/refresh'
const LOGOUT_ENDPOINT = '/api/auth/logout'

const useAxiosNavigation = () => {
    // Use useRef to prevent a re-render in the useEffect.
    // A ref, cannot be used as a useEffect dependency, hence,
    // your linters shouldn't complain about missing dependencies.
    const navRef = useRef(useNavigate())

    const axios = globalRequestContainer.getAxios()

    useEffect(() => {
        const intercetpor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const httpStatusCode = error.response.status
                const httpConfig = error.config
                // 401: Unauthroized
                if (
                    httpStatusCode !== 401 ||
                    httpConfig.url === REFRESH_ENDPOINT ||
                    httpConfig.url === SIGN_IN_ENDPOINT ||
                    httpConfig.url === LOGOUT_ENDPOINT
                ) {
                    return Promise.reject(error)
                }

                try {
                    await refresh()
                    const originalRequestConfig = error.config
                    delete originalRequestConfig.headers['X-Refresh-Token'] // use from defaults
                    // delay original requests until authorization has been completed
                    return axios.request(originalRequestConfig)
                } catch (err) {
                    navRef.current(LogoutRoute.path)
                    return Promise.reject(err)
                }
            },
        )

        return () => {
            axios.interceptors.response.eject(intercetpor)
        }
    }, [])
}

export default function AuthNavigator() {
    useAxiosNavigation()
    return <></>
}
